import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Smartphone, Zap, Brain, Heart, Shield, X, DollarSign, Lightbulb } from 'lucide-react';

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`rounded-lg border bg-white text-gray-800 shadow-sm ${className}`}
    {...props}
  />
));

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={`p-6 ${className}`} {...props} />
));

const Badge = ({ className, variant = "default", ...props }) => (
  <div 
    className={`inline-flex items-center rounded border px-2.5 py-1.5 text-xs font-normal transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 ${
      variant === "secondary" ? "bg-secondary text-secondary-foreground" : "bg-primary text-primary-foreground"
    } ${className}`} 
    {...props} 
  />
);

const Separator = React.forwardRef(
  ({ className, orientation = "horizontal", decorative = true, ...props }, ref) => (
    <div
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={`shrink-0 bg-gray-200 ${
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]"
      } ${className}`}
      {...props}
    />
  )
);

const LandingPage = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const [direction, setDirection] = useState(1);

  const cards = [
    { 
      id: 1, 
      title: "FashionAI", 
      tagline: "Your Personal AI Stylist",
      description: "AI-powered personal stylist app that suggests outfits based on your wardrobe and daily activities.",
      tam: "$50 billion",
      barrierToEntry: 7,
      valueProps: ["Personalized AI recommendations", "Virtual try-on feature", "Sustainable fashion focus"]
    },
    { 
      id: 2, 
      title: "LocalVest", 
      tagline: "Invest in Your Community",
      description: "Blockchain-based platform for micro-investments in local businesses and community projects.",
      tam: "$28 billion",
      barrierToEntry: 8,
      valueProps: ["Fractional ownership in local businesses", "Transparent blockchain transactions", "Community-driven development"]
    },
    { 
      id: 3, 
      title: "CookAR", 
      tagline: "Cooking Made Magical",
      description: "AR cooking assistant that guides you through recipes with holographic instructions.",
      tam: "$15 billion",
      barrierToEntry: 9,
      valueProps: ["Step-by-step AR cooking guide", "AI-powered ingredient substitutions", "Social recipe sharing"]
    },
  ];

  const swipe = useCallback((direction) => {
    setDirection(direction);
    setCurrentCard((prev) => (prev + 1) % cards.length);
  }, [cards.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      swipe(Math.random() < 0.5 ? -1 : 1);
    }, 5000);
    return () => clearInterval(timer);
  }, [swipe]);

  return (
    <div className="min-h-screen bg-white text-gray-800 max-w-[880px] mx-auto">
      <header className="container mx-auto px-4 py-8">
        <nav className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <svg className="w-10 h-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 21H15M12 3C8.13401 3 5 6.13401 5 10C5 12.7614 6.67004 15.2614 9 16.3655V18C9 18.5523 9.44772 19 10 19H14C14.5523 19 15 18.5523 15 18V16.3655C17.33 15.2614 19 12.7614 19 10C19 6.13401 15.866 3 12 3Z" stroke="#8B5CF6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <h1 className="text-3xl font-bold text-gray-800">IdeaSwipe</h1>
          </div>
          <div className="hidden md:block text-sm text-gray-600">
            10,000+ Startup Ideas
          </div>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-12">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
            <motion.h2 
              className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Tinder for Startup Ideas
            </motion.h2>
            <motion.p 
              className="text-xl mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Swipe to discover AI-generated startup ideas, inspired by successes from top VC portfolios, ProductHunt, Y Combinator, and more.
            </motion.p>
            <motion.div 
              className="flex flex-col gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <button className="bg-black text-white px-8 py-3 rounded-full hover:bg-gray-800 transition duration-300 flex items-center justify-center">
                <svg className="w-5 h-5 mr-2" viewBox="0 0 384 512" fill="currentColor">
                  <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                </svg>
                Download for iOS
              </button>
              <button className="bg-black text-white px-8 py-3 rounded-full hover:bg-gray-800 transition duration-300 flex items-center justify-center">
                <svg className="w-5 h-5 mr-2" viewBox="0 0 512 512" fill="currentColor">
                  <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"/>
                </svg>
                Download for Android
              </button>
            </motion.div>
          </div>
          <motion.div 
            className="w-full lg:w-1/2"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            {/* iPhone Silhouette */}
            <div className="relative w-72 h-[576px] mx-auto bg-gray-900 rounded-[60px] shadow-xl p-4 md:mr-0">
              {/* iPhone Notch */}
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-40 h-6 bg-gray-900 rounded-b-3xl"></div>
              {/* App Mockup */}
              <div className="w-full h-full bg-white rounded-[40px] overflow-hidden">
                {/* App Content */}
                <div className="p-4 h-full flex flex-col">
                  <div className="h-4"></div>
                  <div className="flex-grow relative">
                    <div className="absolute inset-0 bg-gray-100 rounded-xl shadow-md transform rotate-3 translate-y-4"></div>
                    <div className="absolute inset-0 bg-gray-200 rounded-xl shadow-md transform -rotate-3 translate-y-2"></div>
                    <AnimatePresence>
                      <motion.div
                        key={currentCard}
                        className="absolute inset-0 bg-white rounded-xl shadow-lg flex flex-col justify-between"
                        initial={{ 
                          scale: 0.8,
                          opacity: 0,
                        }}
                        animate={{ 
                          scale: 1,
                          opacity: 1,
                          backgroundColor: direction === 1 ? '#f0f4f8' : '#faf0f0',
                        }}
                        exit={{ 
                          x: direction * -300,
                          opacity: 0,
                          rotate: direction * -10,
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 300,
                          damping: 30
                        }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        onDragEnd={(_, info) => {
                          if (info.offset.x > 100) swipe(1)
                          else if (info.offset.x < -100) swipe(-1)
                        }}
                        whileTap={{ scale: 1.05 }}
                      >
                        <Card className="h-full">
                          <CardContent className="p-4 flex flex-col h-full">
                            <div className="flex-grow overflow-hidden">
                              <h4 className="text-lg font-semibold mb-1">{cards[currentCard].title}</h4>
                              <p className="text-sm text-gray-600 mb-2">{cards[currentCard].tagline}</p>
                              <p className="text-sm mb-4">{cards[currentCard].description}</p>
                              
                              <div className="flex items-center justify-between mb-4">
                                <div className="flex items-center">
                                  <DollarSign className="w-4 h-4 mr-1 text-green-600" />
                                  <span className="text-xs font-semibold">Est. TAM:</span>
                                </div>
                                <Badge variant="secondary">{cards[currentCard].tam}</Badge>
                              </div>
                              
                              <div className="flex items-center justify-between mb-4">
                                <div className="flex items-center">
                                  <Shield className="w-4 h-4 mr-1 text-blue-600" />
                                  <span className="text-xs font-semibold">Barrier to Entry:</span>
                                </div>
                                <Badge variant="secondary">{cards[currentCard].barrierToEntry}/10</Badge>
                              </div>
                              
                              <Separator className="my-4" />
                              
                              <div>
                                <h5 className="text-[11px] font-semibold mb-2 flex items-center">
                                  <Lightbulb className="w-3 h-3 mr-1 text-yellow-600" />
                                  Value Props:
                                </h5>
                                <div className="grid grid-cols-1 gap-2">
                                  {cards[currentCard].valueProps.map((prop, index) => (
                                    <Badge key={index} variant="outline" className="justify-start text-[10px] font-normal py-0.5">
                                      {prop}
                                    </Badge>
                                  ))}
                                </div>
                              </div>
                            </div>
                            
                            <div className="flex justify-between items-center mt-4">
                              <button className="bg-red-500 text-white p-2 rounded-full" onClick={() => swipe(-1)}>
                                <X className="w-6 h-6" />
                              </button>
                              <button className="bg-green-500 text-white p-2 rounded-full" onClick={() => swipe(1)}>
                                <Heart className="w-6 h-6" />
                              </button>
                            </div>
                          </CardContent>
                        </Card>
                      </motion.div>
                    </AnimatePresence>
                  </div>
                </div>
                {/* App Navigation Bar */}
                <div className="h-16 bg-gray-100 border-t border-gray-200 flex justify-around items-center px-4">
                  <button className="text-gray-700">
                    <Smartphone className="w-6 h-6" />
                  </button>
                  <button className="text-gray-700">
                    <Zap className="w-6 h-6" />
                  </button>
                  <button className="text-gray-700">
                    <Brain className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;
